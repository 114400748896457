export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
}

export enum AccessAvailability {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Unfrequently = 'UNFREQUENTLY',
  Weekly = 'WEEKLY'
}

export enum AccessFrequency {
  Anyday = 'ANYDAY',
  MonToFri = 'MON_TO_FRI',
  Weekends = 'WEEKENDS'
}

export enum AccessHours {
  Anytime = 'ANYTIME',
  DaytimeHours = 'DAYTIME_HOURS',
  EveningHours = 'EVENING_HOURS',
  ExtendedHours = 'EXTENDED_HOURS'
}

export enum AccessPermission {
  Notice = 'NOTICE',
  NoNotice = 'NO_NOTICE'
}

export type IAddress = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']['output']>
  colonia?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  neighborhood?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street1?: Maybe<Scalars['String']['output']>
  street2?: Maybe<Scalars['String']['output']>
  zipcode?: Maybe<Scalars['String']['output']>
}

export type IAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  colonia?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  neighborhood?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street1?: InputMaybe<Scalars['String']['input']>
  street2?: InputMaybe<Scalars['String']['input']>
  zipcode?: InputMaybe<Scalars['String']['input']>
}

export type IAdminEdit = {
  canStoreVehicle?: InputMaybe<Scalars['Boolean']['input']>
  isDeactivated?: InputMaybe<Scalars['Boolean']['input']>
  locationType?: InputMaybe<LocationType>
  price?: InputMaybe<Scalars['Float']['input']>
  visualScore?: InputMaybe<Scalars['Int']['input']>
}

export type IAdminUserEdit = {
  description?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  lastnameMaternal?: InputMaybe<Scalars['String']['input']>
  profilePicture?: InputMaybe<IPhotoInput>
}

export type IAssesVerificationInput = {
  accountVerificationError?: InputMaybe<Scalars['String']['input']>
  isVerified: Scalars['Boolean']['input']
  userId: Scalars['String']['input']
}

export type IAssessCheckoutInput = {
  checkoutId: Scalars['String']['input']
  isLandlordApproved: Scalars['Boolean']['input']
  landlordNotes?: InputMaybe<Scalars['String']['input']>
  rejectionReason?: InputMaybe<Scalars['String']['input']>
}

export enum AuthEmailType {
  ResetPassword = 'RESET_PASSWORD',
  Verification = 'VERIFICATION'
}

export type IBankInfo = {
  __typename?: 'BankInfo'
  accountNumber?: Maybe<Scalars['String']['output']>
  bank?: Maybe<Scalars['String']['output']>
  cardNumber?: Maybe<Scalars['String']['output']>
  cardType?: Maybe<CardType>
  clabe?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
}

export type IBankInfoInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>
  bank?: InputMaybe<Scalars['String']['input']>
  cardNumber?: InputMaybe<Scalars['String']['input']>
  cardType?: InputMaybe<CardType>
  clabe?: InputMaybe<Scalars['String']['input']>
  fullName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type IBusinessInput = {
  locationId?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type IBussiness = {
  __typename?: 'Bussiness'
  description?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  profilePicture?: Maybe<IPhoto>
}

export type IBussinessInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  profilePicture?: InputMaybe<IPhotoInput>
}

export type ICalculatePriceInput = {
  canStoreVehicle: Scalars['Boolean']['input']
  googlePlaceId: Scalars['String']['input']
  quantity: Scalars['Int']['input']
  size: ISizeInput
}

export type ICard = {
  __typename?: 'Card'
  brand?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  expMonth?: Maybe<Scalars['Int']['output']>
  expYear?: Maybe<Scalars['Int']['output']>
  funding?: Maybe<Scalars['String']['output']>
  last4?: Maybe<Scalars['String']['output']>
}

export enum CardType {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type IChat = INode & {
  __typename?: 'Chat'
  checkout?: Maybe<ICheckout>
  createdAt: Scalars['String']['output']
  id: Scalars['String']['output']
  lastMessage?: Maybe<ILastMessage>
  lastMessageTimestamp: Scalars['String']['output']
  lastUpdatedOn: Scalars['String']['output']
  location?: Maybe<ILocation>
  memberIds: Array<Scalars['String']['output']>
  members?: Maybe<Array<IUser>>
  messages?: Maybe<IChatMessagesResponse>
  unreadMessages?: Maybe<Scalars['Int']['output']>
}

export type IChatMessagesArgs = {
  pagination: IPaginationInput
}

export type IChatMessagesResponse = {
  __typename?: 'ChatMessagesResponse'
  messages: Array<IMessage>
  total: Scalars['Int']['output']
}

export type ICheckout = INode & {
  __typename?: 'Checkout'
  accessAvailability?: Maybe<AccessAvailability>
  accessFrequency?: Maybe<AccessFrequency>
  accessHours?: Maybe<AccessHours>
  accessPermission?: Maybe<AccessPermission>
  addressUrl?: Maybe<Scalars['String']['output']>
  cancelledAt?: Maybe<Scalars['String']['output']>
  cancelledBy?: Maybe<Interest>
  completedAt?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  endedAt?: Maybe<Scalars['String']['output']>
  endedReason?: Maybe<Scalars['String']['output']>
  hasEnded?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['String']['output']
  insuranceReceipts?: Maybe<Array<IInsuranceReceipt>>
  insuranceType?: Maybe<InsuranceType>
  invoices?: Maybe<IInvoicesResponse>
  isLandlordApproved?: Maybe<Scalars['Boolean']['output']>
  landlord?: Maybe<IUser>
  landlordAssessedAt?: Maybe<Scalars['String']['output']>
  landlordId?: Maybe<Scalars['String']['output']>
  landlordNotes?: Maybe<Scalars['String']['output']>
  landlordReview?: Maybe<IReview>
  lastUpdatedOn: Scalars['String']['output']
  location?: Maybe<ILocation>
  locationId?: Maybe<Scalars['String']['output']>
  paymentError?: Maybe<Scalars['Boolean']['output']>
  price?: Maybe<Scalars['Float']['output']>
  pricing?: Maybe<IPricing>
  quantity?: Maybe<Scalars['Int']['output']>
  rejectionReason?: Maybe<Scalars['String']['output']>
  renter?: Maybe<IUser>
  renterId?: Maybe<Scalars['String']['output']>
  renterReview?: Maybe<IReview>
  requestedStartDate?: Maybe<Scalars['String']['output']>
  startedAt?: Maybe<Scalars['String']['output']>
  storageItems?: Maybe<Scalars['String']['output']>
  storageItemsPhotos?: Maybe<Array<IPhoto>>
  storageNeeds?: Maybe<Scalars['String']['output']>
  storageTime?: Maybe<StorageTime>
  stripeCheckoutSessionUrl?: Maybe<Scalars['String']['output']>
  stripeChubbSubId?: Maybe<Scalars['String']['output']>
  stripePaymentMethod?: Maybe<IPaymentMethod>
  stripePaymentMethodCompletedAt?: Maybe<Scalars['String']['output']>
  stripePaymentMethodId?: Maybe<Scalars['String']['output']>
  stripePriceId?: Maybe<Scalars['String']['output']>
  stripeSessionCreatedAt?: Maybe<Scalars['String']['output']>
  stripeSessionId?: Maybe<Scalars['String']['output']>
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>
  vehicles?: Maybe<Array<IVehicle>>
}

export type ICheckoutResponse = {
  __typename?: 'CheckoutResponse'
  checkout: ICheckout
}

export enum CheckoutType {
  Active = 'ACTIVE',
  Past = 'PAST',
  Pending = 'PENDING',
  UpComing = 'UP_COMING'
}

export type ICheckoutsResponse = {
  __typename?: 'CheckoutsResponse'
  hits: Array<ICheckout>
  total: Scalars['Int']['output']
}

export enum CivilStatus {
  Divorced = 'DIVORCED',
  LivingTogether = 'LIVING_TOGETHER',
  Married = 'MARRIED',
  NotApplicable = 'NOT_APPLICABLE',
  Seperated = 'SEPERATED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type ICoordinates = {
  __typename?: 'Coordinates'
  lat: Scalars['Float']['output']
  lng: Scalars['Float']['output']
}

export type ICoordinatesInput = {
  lat: Scalars['Float']['input']
  lng: Scalars['Float']['input']
}

export enum Currency {
  Mxn = 'MXN',
  Usd = 'USD'
}

export type ICustomerInfoInput = {
  email: Scalars['String']['input']
  hostType?: InputMaybe<Scalars['String']['input']>
  interest?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  phone: Scalars['String']['input']
  referrer?: InputMaybe<Scalars['String']['input']>
}

export enum CustomerType {
  HostBroker = 'HOST_BROKER',
  HostCorporate = 'HOST_CORPORATE',
  HostIndividual = 'HOST_INDIVIDUAL',
  RentIndividual = 'RENT_INDIVIDUAL'
}

export type IDeleteItemResponse = {
  __typename?: 'DeleteItemResponse'
  success: Scalars['Boolean']['output']
}

export enum Features {
  AirConditioner = 'AIR_CONDITIONER',
  AlarmSystem = 'ALARM_SYSTEM',
  AntiFireSystem = 'ANTI_FIRE_SYSTEM',
  ArmoredSpace = 'ARMORED_SPACE',
  Bathrooms = 'BATHROOMS',
  Cafeteria = 'CAFETERIA',
  ClassificationYard = 'CLASSIFICATION_YARD',
  ClimateControl = 'CLIMATE_CONTROL',
  ElectricalInstallation = 'ELECTRICAL_INSTALLATION',
  Elevator = 'ELEVATOR',
  FloorLevel = 'FLOOR_LEVEL',
  Fumigated = 'FUMIGATED',
  Gas = 'GAS',
  IndependentEntrance = 'INDEPENDENT_ENTRANCE',
  Light = 'LIGHT',
  LockedArea = 'LOCKED_AREA',
  MeetingRooms = 'MEETING_ROOMS',
  Mezzanine = 'MEZZANINE',
  Offices = 'OFFICES',
  PestControl = 'PEST_CONTROL',
  PetFree = 'PET_FREE',
  PrivateEntrance = 'PRIVATE_ENTRANCE',
  PrivateSpace = 'PRIVATE_SPACE',
  Railyard = 'RAILYARD',
  Reception = 'RECEPTION',
  SecurityCamera = 'SECURITY_CAMERA',
  Shelves = 'SHELVES',
  ShieldedSpace = 'SHIELDED_SPACE',
  SmokeDetectors = 'SMOKE_DETECTORS',
  Stairs = 'STAIRS',
  Surveillance = 'SURVEILLANCE',
  Tank = 'TANK',
  Tinaco = 'TINACO',
  Toilets = 'TOILETS',
  TruckOrTrailerAccess = 'TRUCK_OR_TRAILER_ACCESS',
  VehicleAccess = 'VEHICLE_ACCESS',
  Water = 'WATER',
  Waterproofed = 'WATERPROOFED'
}

export type IGoogleAutocompleteSuggestion = {
  __typename?: 'GoogleAutocompleteSuggestion'
  description: Scalars['String']['output']
  placeId: Scalars['String']['output']
}

export type IGooglePlaceDetails = {
  __typename?: 'GooglePlaceDetails'
  address?: Maybe<Scalars['String']['output']>
  addressDetails?: Maybe<IAddress>
  lat: Scalars['Float']['output']
  lng: Scalars['Float']['output']
  placeId: Scalars['String']['output']
}

export type IHostSnapshot = {
  __typename?: 'HostSnapshot'
  activeCheckouts?: Maybe<Scalars['Int']['output']>
  approvedCheckouts?: Maybe<Scalars['Int']['output']>
  asReferrer?: Maybe<Scalars['String']['output']>
  asUser?: Maybe<Scalars['String']['output']>
  monthlyRevenue?: Maybe<Scalars['Float']['output']>
  occupancyRate?: Maybe<Scalars['Float']['output']>
  totalRevenue?: Maybe<Scalars['Float']['output']>
  verifiedLocations?: Maybe<Scalars['Int']['output']>
}

export enum HostType {
  Bodegas = 'BODEGAS',
  ParkingCorporate = 'PARKING_CORPORATE',
  ParkingIndividual = 'PARKING_INDIVIDUAL',
  ParkingPension = 'PARKING_PENSION',
  SelfStorageCorporate = 'SELF_STORAGE_CORPORATE',
  SelfStorageIndividual = 'SELF_STORAGE_INDIVIDUAL'
}

export enum IndoorOutdoor {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR',
  OutdoorUncovered = 'OUTDOOR_UNCOVERED'
}

export type IInsurancePolicy = {
  __typename?: 'InsurancePolicy'
  ClaveId?: Maybe<Scalars['String']['output']>
  PolizaId?: Maybe<Scalars['Int']['output']>
}

export type IInsuranceQuote = {
  __typename?: 'InsuranceQuote'
  insuranceType?: Maybe<InsuranceType>
  price?: Maybe<Scalars['Float']['output']>
  subtotal?: Maybe<Scalars['Float']['output']>
}

export type IInsuranceQuoteInput = {
  insuranceType?: InputMaybe<InsuranceType>
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type IInsuranceReceipt = {
  __typename?: 'InsuranceReceipt'
  endDate?: Maybe<Scalars['String']['output']>
  policies?: Maybe<Array<IInsurancePolicy>>
  startDate?: Maybe<Scalars['String']['output']>
}

export enum InsuranceType {
  Minimum = 'MINIMUM',
  Moderate = 'MODERATE',
  None = 'NONE',
  Standard = 'STANDARD'
}

export enum Interest {
  Host = 'HOST',
  Rent = 'RENT'
}

export type IInvoice = {
  __typename?: 'Invoice'
  amount?: Maybe<Scalars['Float']['output']>
  createdAt?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  paid?: Maybe<Scalars['Boolean']['output']>
  paidAt?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type IInvoicesResponse = {
  __typename?: 'InvoicesResponse'
  hits: Array<IInvoice>
  total: Scalars['Int']['output']
}

export enum Language {
  En = 'en',
  Es = 'es'
}

export type ILastMessage = {
  __typename?: 'LastMessage'
  files?: Maybe<Array<IPhoto>>
  message?: Maybe<Scalars['String']['output']>
}

export type ILocation = INode & {
  __typename?: 'Location'
  accessAvailability?: Maybe<AccessAvailability>
  accessFrequency?: Maybe<AccessFrequency>
  accessHours?: Maybe<AccessHours>
  accessPermission?: Maybe<AccessPermission>
  address?: Maybe<Scalars['String']['output']>
  addressDetails?: Maybe<IAddress>
  agreedToTerms?: Maybe<Scalars['Boolean']['output']>
  availability?: Maybe<Scalars['Int']['output']>
  bussinessProfile?: Maybe<IBussiness>
  canStoreVehicle?: Maybe<Scalars['Boolean']['output']>
  contract?: Maybe<IPhoto>
  coordinates?: Maybe<ICoordinates>
  coverPhoto?: Maybe<IPhoto>
  description?: Maybe<Scalars['String']['output']>
  discountEnabled?: Maybe<Scalars['Boolean']['output']>
  dynamicPricing?: Maybe<Scalars['Boolean']['output']>
  features?: Maybe<Array<Features>>
  firstVerifiedOn?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  indoorOrOutdoor?: Maybe<IndoorOutdoor>
  isDeactivated?: Maybe<Scalars['Boolean']['output']>
  isDeleted?: Maybe<Scalars['Boolean']['output']>
  isDraft?: Maybe<Scalars['Boolean']['output']>
  isVerified?: Maybe<Scalars['Boolean']['output']>
  lastUpdatedOn: Scalars['String']['output']
  lastVerifiedOn?: Maybe<Scalars['String']['output']>
  locationType?: Maybe<LocationType>
  minPrice?: Maybe<Scalars['Float']['output']>
  photos?: Maybe<Array<IPhoto>>
  price?: Maybe<Scalars['Float']['output']>
  pricing?: Maybe<IPricing>
  privateSpace?: Maybe<Scalars['Boolean']['output']>
  propertyType?: Maybe<PropertyType>
  publicAddress?: Maybe<Scalars['Boolean']['output']>
  quantity?: Maybe<Scalars['Int']['output']>
  reasonForDenial?: Maybe<Scalars['String']['output']>
  reports?: Maybe<IReportsResponse>
  requestedVerification?: Maybe<Scalars['Boolean']['output']>
  shortAddress?: Maybe<Scalars['String']['output']>
  size?: Maybe<ISize>
  step?: Maybe<Scalars['String']['output']>
  submittedBy?: Maybe<Scalars['String']['output']>
  submittedOn?: Maybe<Scalars['String']['output']>
  suggestedPrice?: Maybe<Scalars['Float']['output']>
  title?: Maybe<Scalars['String']['output']>
  traditionalListing?: Maybe<Scalars['Boolean']['output']>
  urlRoute?: Maybe<Scalars['String']['output']>
  useOfSpace?: Maybe<UseOfSpace>
  user?: Maybe<IUser>
  verifiedBy?: Maybe<Scalars['String']['output']>
  visualScore?: Maybe<Scalars['Int']['output']>
  welcomeMessage?: Maybe<Scalars['String']['output']>
}

export type ILocationReportsArgs = {
  pagination: IPaginationInput
}

export type ILocationAssessment = {
  isVerified: Scalars['Boolean']['input']
  locationId: Scalars['String']['input']
  reasonForDenial?: InputMaybe<Scalars['String']['input']>
}

export enum LocationType {
  Attic = 'ATTIC',
  Basement = 'BASEMENT',
  BodegaCommercial = 'BODEGA_COMMERCIAL',
  BodegaIndustrial = 'BODEGA_INDUSTRIAL',
  Closet = 'CLOSET',
  Garage = 'GARAGE',
  House = 'HOUSE',
  Land = 'LAND',
  Local = 'LOCAL',
  MiniWinery = 'MINI_WINERY',
  NaveIndustrial = 'NAVE_INDUSTRIAL',
  Office = 'OFFICE',
  Other = 'OTHER',
  Parking = 'PARKING',
  Penthouse = 'PENTHOUSE',
  Room = 'ROOM',
  SelfStorageUnit = 'SELF_STORAGE_UNIT',
  Shed = 'SHED',
  ShippingContainer = 'SHIPPING_CONTAINER',
  Terrace = 'TERRACE',
  Warehouse = 'WAREHOUSE',
  Yard = 'YARD'
}

export type IMessage = {
  __typename?: 'Message'
  author?: Maybe<IUser>
  authorId: Scalars['String']['output']
  chatId: Scalars['String']['output']
  checkout?: Maybe<ICheckout>
  event?: Maybe<IMessageEvent>
  files?: Maybe<Array<IPhoto>>
  id: Scalars['String']['output']
  locations?: Maybe<Array<ILocation>>
  message?: Maybe<Scalars['String']['output']>
  readReceipts: Array<IReadReceipt>
  reported?: Maybe<Scalars['Boolean']['output']>
  timestamp: Scalars['String']['output']
}

export type IMessageEvent = {
  __typename?: 'MessageEvent'
  date?: Maybe<Scalars['String']['output']>
  eventType?: Maybe<MessageEventType>
}

export enum MessageEventType {
  CheckoutCancelled = 'CHECKOUT_CANCELLED',
  CheckoutEnded = 'CHECKOUT_ENDED',
  CheckoutStart = 'CHECKOUT_START',
  RequestAccepted = 'REQUEST_ACCEPTED',
  RequestRejected = 'REQUEST_REJECTED',
  RequestSent = 'REQUEST_SENT'
}

export type IMutation = {
  __typename?: 'Mutation'
  adminEdit: ILocation
  adminUserEdit: IRequestVerificationResponse
  applyReferrer: IRequestVerificationResponse
  assesVerification: IRequestVerificationResponse
  assessCheckout: ICheckoutResponse
  assessReferrer: IRequestVerificationResponse
  calculatePrice: ILocation
  cancelCheckout: ICheckoutResponse
  clearUser?: Maybe<IUser>
  createCheckout: ICheckoutResponse
  createLocation: ISubmitLocationResponse
  createTeam: ITeamResponse
  deleteLocation: IDeleteItemResponse
  deleteTeam: ITeamResponse
  newMessage: IChat
  paymentSetupComplete: ICheckoutResponse
  readMessages: IChat
  registerUser: IRequestVerificationResponse
  reportLocation: ILocation
  reportMessage: Scalars['Boolean']['output']
  reportUser: Scalars['Boolean']['output']
  respondToReview: ICheckoutResponse
  reviewCheckout: ICheckoutResponse
  saveBankInfo: IRequestVerificationResponse
  saveCheckout: ICheckoutResponse
  saveLocation: ISubmitLocationResponse
  saveUserPreferences: IRequestVerificationResponse
  saveUserVerification: IRequestVerificationResponse
  sendAuthEmail: Scalars['Boolean']['output']
  sendCustomerInfoEmail?: Maybe<Scalars['Boolean']['output']>
  toggleLocationActive: ISubmitLocationResponse
  updateNotificationPreferences: IUser
  updateTeam: ITeamResponse
  verifyLocation: IVerifyLocationResponse
  verifyReportedLocation: ILocation
  verifyReportedUser: IRequestVerificationResponse
}

export type IMutationAdminEditArgs = {
  edit: IAdminEdit
  locationId: Scalars['String']['input']
}

export type IMutationAdminUserEditArgs = {
  edit: IAdminUserEdit
  userId: Scalars['String']['input']
}

export type IMutationAssesVerificationArgs = {
  assess: IAssesVerificationInput
}

export type IMutationAssessCheckoutArgs = {
  assessment: IAssessCheckoutInput
}

export type IMutationAssessReferrerArgs = {
  assess: IAssesVerificationInput
}

export type IMutationCalculatePriceArgs = {
  lead?: InputMaybe<ICustomerInfoInput>
  loc: ICalculatePriceInput
}

export type IMutationCancelCheckoutArgs = {
  checkoutId: Scalars['String']['input']
  endedReason?: InputMaybe<Scalars['String']['input']>
}

export type IMutationCreateCheckoutArgs = {
  locationId: Scalars['String']['input']
  saveCheckout: ISaveCheckoutInput
}

export type IMutationCreateTeamArgs = {
  input: IUpdateTeamInput
}

export type IMutationDeleteLocationArgs = {
  locationId: Scalars['String']['input']
}

export type IMutationDeleteTeamArgs = {
  teamId: Scalars['String']['input']
}

export type IMutationNewMessageArgs = {
  chatId?: InputMaybe<Scalars['String']['input']>
  message: INewMessageInput
  recipientIds: Array<Scalars['String']['input']>
}

export type IMutationPaymentSetupCompleteArgs = {
  stripeSessionId: Scalars['String']['input']
}

export type IMutationReadMessagesArgs = {
  chatId: Scalars['String']['input']
}

export type IMutationRegisterUserArgs = {
  input: IRegisterUserInput
}

export type IMutationReportLocationArgs = {
  locationId: Scalars['String']['input']
  reason: Scalars['String']['input']
}

export type IMutationReportMessageArgs = {
  chatId: Scalars['String']['input']
  messageId: Scalars['String']['input']
}

export type IMutationReportUserArgs = {
  input: IReportUserInput
}

export type IMutationRespondToReviewArgs = {
  checkoutId: Scalars['String']['input']
  response: Scalars['String']['input']
}

export type IMutationReviewCheckoutArgs = {
  checkoutId: Scalars['String']['input']
  rating: Scalars['Int']['input']
  review: Scalars['String']['input']
}

export type IMutationSaveBankInfoArgs = {
  bankInfo: IBankInfoInput
  userId: Scalars['String']['input']
}

export type IMutationSaveCheckoutArgs = {
  checkoutId: Scalars['String']['input']
  createStripeCheckoutSession?: InputMaybe<Scalars['Boolean']['input']>
  saveCheckout: ISaveCheckoutInput
  submitCheckoutRequest?: InputMaybe<Scalars['Boolean']['input']>
}

export type IMutationSaveLocationArgs = {
  location: ISubmitLocationInput
  submitForVerification?: InputMaybe<Scalars['Boolean']['input']>
}

export type IMutationSaveUserPreferencesArgs = {
  userChanges: IRequestPreferencesInput
}

export type IMutationSaveUserVerificationArgs = {
  requestUserVerification: Scalars['Boolean']['input']
  savingUserProfile?: InputMaybe<Scalars['Boolean']['input']>
  userChanges: IRequestVerificationInput
}

export type IMutationSendAuthEmailArgs = {
  email: Scalars['String']['input']
  emailType: AuthEmailType
  redirectUrl?: InputMaybe<Scalars['String']['input']>
}

export type IMutationSendCustomerInfoEmailArgs = {
  customerInfo: ICustomerInfoInput
  searchInput?: InputMaybe<ISearchLocationsInput>
}

export type IMutationToggleLocationActiveArgs = {
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type IMutationUpdateNotificationPreferencesArgs = {
  input: INotificationInput
}

export type IMutationUpdateTeamArgs = {
  input: IUpdateTeamInput
  teamId: Scalars['String']['input']
}

export type IMutationVerifyLocationArgs = {
  verification: ILocationAssessment
}

export type IMutationVerifyReportedLocationArgs = {
  verification: ILocationAssessment
}

export type IMutationVerifyReportedUserArgs = {
  verification: IAssesVerificationInput
}

export type INewMessageInput = {
  checkoutId?: InputMaybe<Scalars['String']['input']>
  files?: InputMaybe<Array<IPhotoInput>>
  locations?: InputMaybe<Array<Scalars['String']['input']>>
  message?: InputMaybe<Scalars['String']['input']>
}

export type INode = {
  id: Scalars['String']['output']
  lastUpdatedOn: Scalars['String']['output']
}

export enum NotificationGenre {
  AccountActivity = 'ACCOUNT_ACTIVITY',
  Messages = 'MESSAGES',
  News = 'NEWS',
  Perks = 'PERKS',
  PolicyChange = 'POLICY_CHANGE',
  Promotions = 'PROMOTIONS',
  Reminders = 'REMINDERS'
}

export type INotificationInput = {
  email?: InputMaybe<Scalars['String']['input']>
  notiPreferences?: InputMaybe<Array<INotificationPreferenceInput>>
  preferedNotiMethod?: InputMaybe<NotificationMethod>
  unsubscribeAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum NotificationMethod {
  Email = 'EMAIL',
  Push = 'PUSH',
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP'
}

export type INotificationPreference = {
  __typename?: 'NotificationPreference'
  disabledMethods?: Maybe<Array<NotificationMethod>>
  genre?: Maybe<NotificationGenre>
}

export type INotificationPreferenceInput = {
  disabledMethods?: InputMaybe<Array<NotificationMethod>>
  genre?: InputMaybe<NotificationGenre>
}

export type INumRangeInput = {
  max?: InputMaybe<Scalars['Float']['input']>
  min?: InputMaybe<Scalars['Float']['input']>
}

export type IOfac = {
  __typename?: 'Ofac'
  hasMatch?: Maybe<Scalars['Boolean']['output']>
  matches?: Maybe<Scalars['String']['output']>
}

export type IPaginationInput = {
  from: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
}

export type IPaymentMethod = {
  __typename?: 'PaymentMethod'
  card?: Maybe<ICard>
  created?: Maybe<Scalars['Int']['output']>
  id: Scalars['String']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type IPayout = {
  __typename?: 'Payout'
  amount?: Maybe<Scalars['Float']['output']>
  arrivalDate?: Maybe<Scalars['String']['output']>
  created?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type IPayoutsResponse = {
  __typename?: 'PayoutsResponse'
  hits: Array<IPayout>
  total: Scalars['Int']['output']
}

export type IPhoto = {
  __typename?: 'Photo'
  height?: Maybe<Scalars['Int']['output']>
  id: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  nonPublic?: Maybe<Scalars['Boolean']['output']>
  url?: Maybe<Scalars['String']['output']>
  width?: Maybe<Scalars['Int']['output']>
}

export type IPhotoInput = {
  height?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  nonPublic?: InputMaybe<Scalars['Boolean']['input']>
  url?: InputMaybe<Scalars['String']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type IPhotoUpload = {
  __typename?: 'PhotoUpload'
  id: Scalars['String']['output']
  signedUrl?: Maybe<Scalars['String']['output']>
  uploadUrl: Scalars['String']['output']
}

export type IPricing = {
  __typename?: 'Pricing'
  /** Impuestos */
  impuestos?: Maybe<Scalars['Float']['output']>
  /** Tarfifa de Seguro */
  insurance?: Maybe<Scalars['Float']['output']>
  /** Subtotal */
  subtotal?: Maybe<Scalars['Float']['output']>
  /** Tarfifa de Espacio */
  tde?: Maybe<Scalars['Float']['output']>
  /** Tarfifa de Espacio Recibida por Anfition */
  tdera?: Maybe<Scalars['Float']['output']>
  /** Tarfifa de Procesamiento */
  tdp?: Maybe<Scalars['Float']['output']>
  /** Tarfifa de Servicio */
  tds?: Maybe<Scalars['Float']['output']>
  /** Tarfifa Final */
  tf?: Maybe<Scalars['Float']['output']>
  /** Tarfifa final sin seguro */
  tfNoInsurance?: Maybe<Scalars['Float']['output']>
}

export enum PropertyType {
  Brokerage = 'BROKERAGE',
  Commercial = 'COMMERCIAL',
  IndividualBroker = 'INDIVIDUAL_BROKER',
  Residential = 'RESIDENTIAL'
}

export type IQuery = {
  __typename?: 'Query'
  adminChats: IUserChatsResponse
  authenticated: Scalars['Boolean']['output']
  chat: IChat
  chatByMembers?: Maybe<IChat>
  checkout: ICheckout
  commercialLocations: ISearchLocationResponse
  currentUser?: Maybe<IUser>
  emailBelongsToUser: Scalars['Boolean']['output']
  generateUploadUrls: Array<IPhotoUpload>
  googleAddress: IAddress
  googleAutocompleteSuggestions: Array<IGoogleAutocompleteSuggestion>
  googleCoordinates: IGooglePlaceDetails
  googlePlaceDetails: IGooglePlaceDetails
  insuranceQuote?: Maybe<Array<IInsuranceQuote>>
  location: ILocation
  locationVerificationRequests: ISearchLocationResponse
  ofacQuery: IOfac
  recommendedLocations: ISearchLocationResponse
  referredUsers: IUsersResponse
  rents: ICheckoutsResponse
  reportedLocations: ISearchLocationResponse
  reportedUsers: IUsersResponse
  reservations: ICheckoutsResponse
  searchLocations: ISearchLocationResponse
  searchUsers: IUsersResponse
  team: ITeam
  teams: ITeamResponse
  unreadMessages: IChatMessagesResponse
  user: IUser
  userByNanoId: IUser
  userChats: IUserChatsResponse
  userLeads: IUsersResponse
  userLocations: ISearchLocationResponse
  userVerificationRequests: IUsersResponse
}

export type IQueryAdminChatsArgs = {
  pagination: IPaginationInput
}

export type IQueryChatArgs = {
  chatId: Scalars['String']['input']
}

export type IQueryChatByMembersArgs = {
  locationId?: InputMaybe<Scalars['String']['input']>
  memberIds: Array<Scalars['String']['input']>
}

export type IQueryCheckoutArgs = {
  checkoutId: Scalars['String']['input']
}

export type IQueryCommercialLocationsArgs = {
  pagination: IPaginationInput
}

export type IQueryCurrentUserArgs = {
  referrer?: InputMaybe<Scalars['String']['input']>
}

export type IQueryEmailBelongsToUserArgs = {
  email: Scalars['String']['input']
}

export type IQueryGenerateUploadUrlsArgs = {
  count?: InputMaybe<Scalars['Int']['input']>
  nonPublic?: InputMaybe<Scalars['Boolean']['input']>
}

export type IQueryGoogleAddressArgs = {
  lat: Scalars['Float']['input']
  lng: Scalars['Float']['input']
}

export type IQueryGoogleAutocompleteSuggestionsArgs = {
  searchValue: Scalars['String']['input']
}

export type IQueryGoogleCoordinatesArgs = {
  address: Scalars['String']['input']
}

export type IQueryGooglePlaceDetailsArgs = {
  placeId: Scalars['String']['input']
}

export type IQueryInsuranceQuoteArgs = {
  insuranceInput: IInsuranceQuoteInput
}

export type IQueryLocationArgs = {
  locationId: Scalars['String']['input']
}

export type IQueryLocationVerificationRequestsArgs = {
  pagination: IPaginationInput
}

export type IQueryOfacQueryArgs = {
  firstname: Scalars['String']['input']
  lastname: Scalars['String']['input']
  lastnameMaternal: Scalars['String']['input']
}

export type IQueryRecommendedLocationsArgs = {
  pagination: IPaginationInput
  searchInput: ISearchLocationsInput
}

export type IQueryReferredUsersArgs = {
  pagination: IPaginationInput
}

export type IQueryRentsArgs = {
  pagination: IPaginationInput
  searchInput: ISearchCheckoutsInput
}

export type IQueryReportedLocationsArgs = {
  pagination: IPaginationInput
}

export type IQueryReportedUsersArgs = {
  pagination: IPaginationInput
}

export type IQueryReservationsArgs = {
  pagination: IPaginationInput
  searchInput: ISearchCheckoutsInput
}

export type IQuerySearchLocationsArgs = {
  pagination: IPaginationInput
  searchInput: ISearchLocationsInput
}

export type IQuerySearchUsersArgs = {
  options: IUserSearchInput
  pagination: IPaginationInput
}

export type IQueryTeamArgs = {
  id: Scalars['String']['input']
}

export type IQueryUnreadMessagesArgs = {
  chatId?: InputMaybe<Scalars['String']['input']>
}

export type IQueryUserArgs = {
  userId: Scalars['String']['input']
}

export type IQueryUserByNanoIdArgs = {
  nanoId: Scalars['String']['input']
}

export type IQueryUserChatsArgs = {
  pagination: IPaginationInput
}

export type IQueryUserLeadsArgs = {
  pagination: IPaginationInput
}

export type IQueryUserLocationsArgs = {
  pagination: IPaginationInput
  userLocationsInput: IUserLocationsInput
}

export type IQueryUserVerificationRequestsArgs = {
  pagination: IPaginationInput
}

export type IReadReceipt = {
  __typename?: 'ReadReceipt'
  memberId: Scalars['String']['output']
  read: Scalars['Boolean']['output']
}

export enum ReferrerStatus {
  Accepted = 'ACCEPTED',
  Na = 'NA',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum RegimenFiscal {
  Id_601 = 'ID_601',
  Id_603 = 'ID_603',
  Id_605 = 'ID_605',
  Id_606 = 'ID_606',
  Id_607 = 'ID_607',
  Id_608 = 'ID_608',
  Id_610 = 'ID_610',
  Id_611 = 'ID_611',
  Id_612 = 'ID_612',
  Id_614 = 'ID_614',
  Id_615 = 'ID_615',
  Id_616 = 'ID_616',
  Id_620 = 'ID_620',
  Id_621 = 'ID_621',
  Id_622 = 'ID_622',
  Id_623 = 'ID_623',
  Id_624 = 'ID_624',
  Id_625 = 'ID_625',
  Id_626 = 'ID_626'
}

export type IRegisterUserInput = {
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  profilePicture?: InputMaybe<IPhotoInput>
  referrer?: InputMaybe<Scalars['String']['input']>
}

export type IReport = {
  __typename?: 'Report'
  chatId?: Maybe<Scalars['String']['output']>
  reason?: Maybe<Scalars['String']['output']>
  reportedAt?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['String']['output']>
}

export type IReportUserInput = {
  chatId?: InputMaybe<Scalars['String']['input']>
  reason: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type IReportsResponse = {
  __typename?: 'ReportsResponse'
  reports: Array<IReport>
  total: Scalars['Int']['output']
}

export type IRequestPreferencesInput = {
  currency?: InputMaybe<Currency>
  language?: InputMaybe<Language>
}

export type IRequestSavePreferencesResponse = {
  __typename?: 'RequestSavePreferencesResponse'
  user: IUser
}

export type IRequestVerificationInput = {
  actividadEmpresarial?: InputMaybe<Scalars['Boolean']['input']>
  address?: InputMaybe<IAddressInput>
  birthdate?: InputMaybe<Scalars['String']['input']>
  civilStatus?: InputMaybe<CivilStatus>
  constanciaFiscal?: InputMaybe<IPhotoInput>
  currentCity?: InputMaybe<Scalars['String']['input']>
  customerType?: InputMaybe<CustomerType>
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  favoriteSong?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  hobbie?: InputMaybe<Scalars['String']['input']>
  hostType?: InputMaybe<HostType>
  identification?: InputMaybe<IPhotoInput>
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  interest?: InputMaybe<Interest>
  job?: InputMaybe<Scalars['String']['input']>
  languages?: InputMaybe<Array<Scalars['String']['input']>>
  lastname?: InputMaybe<Scalars['String']['input']>
  lastnameMaternal?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
  movieAsLife?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  profilePicture?: InputMaybe<IPhotoInput>
  regimenFiscal?: InputMaybe<RegimenFiscal>
  rfc?: InputMaybe<Scalars['String']['input']>
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['String']['input']
  usoCFDI?: InputMaybe<UsoCfdi>
}

export type IRequestVerificationResponse = {
  __typename?: 'RequestVerificationResponse'
  user: IUser
}

export type IReview = {
  __typename?: 'Review'
  createdAt?: Maybe<Scalars['String']['output']>
  interest?: Maybe<Interest>
  rating?: Maybe<Scalars['Float']['output']>
  respondedAt?: Maybe<Scalars['String']['output']>
  response?: Maybe<Scalars['String']['output']>
  review?: Maybe<Scalars['String']['output']>
  reviewer?: Maybe<IUser>
  reviewerId?: Maybe<Scalars['String']['output']>
}

export type IReviewResponse = {
  __typename?: 'ReviewResponse'
  avgRating?: Maybe<Scalars['Float']['output']>
  hits?: Maybe<Array<IReview>>
  total?: Maybe<Scalars['Int']['output']>
}

export type ISaveCheckoutInput = {
  insuranceType?: InputMaybe<InsuranceType>
  quantity?: InputMaybe<Scalars['Int']['input']>
  requestedStartDate?: InputMaybe<Scalars['String']['input']>
  storageItems?: InputMaybe<Scalars['String']['input']>
  storageItemsPhotos?: InputMaybe<Array<IPhotoInput>>
  storageNeeds?: InputMaybe<Scalars['String']['input']>
  storageTime?: InputMaybe<StorageTime>
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>
  vehicles?: InputMaybe<Array<IVehicleInput>>
}

export type ISearchCheckoutsInput = {
  type?: InputMaybe<CheckoutType>
}

export type ISearchLocationResponse = {
  __typename?: 'SearchLocationResponse'
  fallback?: Maybe<Scalars['Boolean']['output']>
  hits: Array<ILocation>
  total: Scalars['Int']['output']
}

export type ISearchLocationsInput = {
  canStoreVehicle?: InputMaybe<Scalars['Boolean']['input']>
  center?: InputMaybe<ICoordinatesInput>
  placeId?: InputMaybe<Scalars['String']['input']>
  priceRange?: InputMaybe<INumRangeInput>
  size?: InputMaybe<SizeFilter>
  sortBy?: InputMaybe<SortBy>
  zoom?: InputMaybe<Scalars['Float']['input']>
}

export type ISize = {
  __typename?: 'Size'
  height?: Maybe<Scalars['Float']['output']>
  length: Scalars['Float']['output']
  width: Scalars['Float']['output']
}

export enum SizeFilter {
  Any = 'ANY',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type ISizeInput = {
  height?: InputMaybe<Scalars['Float']['input']>
  length: Scalars['Float']['input']
  width: Scalars['Float']['input']
}

export enum SortBy {
  Closest = 'CLOSEST',
  PriceHighToLow = 'PRICE_HIGH_TO_LOW',
  PriceLowToHigh = 'PRICE_LOW_TO_HIGH',
  Recommended = 'RECOMMENDED'
}

export enum StorageTime {
  FewMonths = 'FEW_MONTHS',
  FewYears = 'FEW_YEARS',
  Indefinitely = 'INDEFINITELY',
  Month = 'MONTH',
  Year = 'YEAR'
}

export type ISubmitLocationInput = {
  accessAvailability?: InputMaybe<AccessAvailability>
  accessFrequency?: InputMaybe<AccessFrequency>
  accessHours?: InputMaybe<AccessHours>
  accessPermission?: InputMaybe<AccessPermission>
  addressDetails?: InputMaybe<IAddressInput>
  agreedToTerms?: InputMaybe<Scalars['Boolean']['input']>
  bussinessProfile?: InputMaybe<IBussinessInput>
  canStoreVehicle?: InputMaybe<Scalars['Boolean']['input']>
  contract?: InputMaybe<IPhotoInput>
  coordinates?: InputMaybe<ICoordinatesInput>
  coverPhoto?: InputMaybe<IPhotoInput>
  description?: InputMaybe<Scalars['String']['input']>
  discountEnabled?: InputMaybe<Scalars['Boolean']['input']>
  dynamicPricing?: InputMaybe<Scalars['Boolean']['input']>
  features?: InputMaybe<Array<Features>>
  id: Scalars['String']['input']
  indoorOrOutdoor?: InputMaybe<IndoorOutdoor>
  locationType?: InputMaybe<LocationType>
  minPrice?: InputMaybe<Scalars['Float']['input']>
  photos?: InputMaybe<Array<IPhotoInput>>
  price?: InputMaybe<Scalars['Float']['input']>
  privateSpace?: InputMaybe<Scalars['Boolean']['input']>
  propertyType?: InputMaybe<PropertyType>
  quantity?: InputMaybe<Scalars['Int']['input']>
  referrer?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<ISizeInput>
  step?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  useOfSpace?: InputMaybe<UseOfSpace>
  welcomeMessage?: InputMaybe<Scalars['String']['input']>
}

export type ISubmitLocationResponse = {
  __typename?: 'SubmitLocationResponse'
  location: ILocation
}

export type ITeam = INode & {
  __typename?: 'Team'
  createdOn?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  icon?: Maybe<IPhoto>
  id: Scalars['String']['output']
  lastUpdatedOn: Scalars['String']['output']
  logo?: Maybe<IPhoto>
  members?: Maybe<Array<ITeamMember>>
  name?: Maybe<Scalars['String']['output']>
}

export type ITeamMember = {
  __typename?: 'TeamMember'
  addedOn?: Maybe<Scalars['String']['output']>
  role?: Maybe<TeamRole>
  userId?: Maybe<Scalars['String']['output']>
}

export type ITeamMemberInput = {
  role: TeamRole
  userId: Scalars['String']['input']
}

export type ITeamResponse = {
  __typename?: 'TeamResponse'
  team: ITeam
}

export enum TeamRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type ITeamsResponse = {
  __typename?: 'TeamsResponse'
  hits: Array<ITeam>
  total: Scalars['Int']['output']
}

export type IUpdateTeamInput = {
  description?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<IPhotoInput>
  logo?: InputMaybe<IPhotoInput>
  members?: InputMaybe<Array<ITeamMemberInput>>
  name: Scalars['String']['input']
}

export enum UseOfSpace {
  Commercial = 'COMMERCIAL',
  Industrial = 'INDUSTRIAL',
  Mixed = 'MIXED',
  None = 'NONE',
  Residencial = 'RESIDENCIAL'
}

export type IUser = INode & {
  __typename?: 'User'
  accountVerificationError?: Maybe<Scalars['String']['output']>
  actividadEmpresarial?: Maybe<Scalars['Boolean']['output']>
  address?: Maybe<IAddress>
  bankInfo?: Maybe<IBankInfo>
  birthdate?: Maybe<Scalars['String']['output']>
  bussinessProfile?: Maybe<IBussiness>
  civilStatus?: Maybe<CivilStatus>
  constanciaFiscal?: Maybe<IPhoto>
  createdOn?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Currency>
  currentCity?: Maybe<Scalars['String']['output']>
  customerType?: Maybe<CustomerType>
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  facebookUrl?: Maybe<Scalars['String']['output']>
  favoriteSong?: Maybe<Scalars['String']['output']>
  firstVerifiedOn?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  hobbie?: Maybe<Scalars['String']['output']>
  hostSnapshot?: Maybe<IHostSnapshot>
  hostType?: Maybe<HostType>
  id: Scalars['String']['output']
  identification?: Maybe<IPhoto>
  instagramUrl?: Maybe<Scalars['String']['output']>
  interest?: Maybe<Interest>
  isAdmin?: Maybe<Scalars['Boolean']['output']>
  isVerified?: Maybe<Scalars['Boolean']['output']>
  job?: Maybe<Scalars['String']['output']>
  language?: Maybe<Language>
  languages?: Maybe<Array<Scalars['String']['output']>>
  lastUpdatedOn: Scalars['String']['output']
  lastVerifiedOn?: Maybe<Scalars['String']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  lastnameMaternal?: Maybe<Scalars['String']['output']>
  listings?: Maybe<ISearchLocationResponse>
  movieAsLife?: Maybe<Scalars['String']['output']>
  nanoId?: Maybe<Scalars['String']['output']>
  notiPreferences?: Maybe<Array<INotificationPreference>>
  ofac?: Maybe<IOfac>
  paymentMethods?: Maybe<Array<IPaymentMethod>>
  payouts?: Maybe<IPayoutsResponse>
  phoneNumber?: Maybe<Scalars['String']['output']>
  preferedNotiMethod?: Maybe<NotificationMethod>
  profilePicture?: Maybe<IPhoto>
  referrer?: Maybe<Scalars['String']['output']>
  referrerStatus?: Maybe<ReferrerStatus>
  referrerUser?: Maybe<Scalars['String']['output']>
  regimenFiscal?: Maybe<RegimenFiscal>
  reports?: Maybe<IReportsResponse>
  requestedVerification?: Maybe<Scalars['Boolean']['output']>
  reviews?: Maybe<IReviewResponse>
  rfc?: Maybe<Scalars['String']['output']>
  salesReferrer?: Maybe<Scalars['String']['output']>
  salesReferrerUser?: Maybe<Scalars['String']['output']>
  shouldSetUpBankAccount?: Maybe<Scalars['Boolean']['output']>
  stripeAccountDashboardLink?: Maybe<Scalars['String']['output']>
  stripeAccountLink?: Maybe<Scalars['String']['output']>
  stripeAccountPayoutError?: Maybe<Scalars['Boolean']['output']>
  stripeCustomerPaymentError?: Maybe<Scalars['Boolean']['output']>
  stripeCustomerPortalLink?: Maybe<Scalars['String']['output']>
  twitterUrl?: Maybe<Scalars['String']['output']>
  userChanges?: Maybe<IUserInfoChanges>
  usoCFDI?: Maybe<UsoCfdi>
  veriffStatus?: Maybe<VeriffStatus>
  verifiedBy?: Maybe<Scalars['String']['output']>
}

export type IUserHostSnapshotArgs = {
  asReferrer?: InputMaybe<Scalars['Boolean']['input']>
}

export type IUserListingsArgs = {
  business?: InputMaybe<IBusinessInput>
  pagination: IPaginationInput
}

export type IUserReportsArgs = {
  pagination: IPaginationInput
}

export type IUserReviewsArgs = {
  interest?: InputMaybe<Interest>
  pagination: IPaginationInput
}

export type IUserStripeAccountLinkArgs = {
  returnPath?: InputMaybe<Scalars['String']['input']>
}

export type IUserStripeCustomerPortalLinkArgs = {
  returnPath?: InputMaybe<Scalars['String']['input']>
}

export type IUserChatsResponse = {
  __typename?: 'UserChatsResponse'
  chats: Array<IChat>
  total: Scalars['Int']['output']
}

export type IUserInfoChanges = {
  __typename?: 'UserInfoChanges'
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  lastnameMaternal?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  profilePicture?: Maybe<IPhoto>
}

export enum UserLocationsFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Drafts = 'DRAFTS',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export type IUserLocationsInput = {
  filter: UserLocationsFilter
  rejected?: InputMaybe<Scalars['Boolean']['input']>
}

export type IUserSearchInput = {
  admins?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type IUsersResponse = {
  __typename?: 'UsersResponse'
  total: Scalars['Int']['output']
  users: Array<IUser>
}

export enum UsoCfdi {
  IdCp01 = 'Id_CP01',
  IdD07 = 'Id_D07',
  IdG01 = 'Id_G01',
  IdG02 = 'Id_G02',
  IdG03 = 'Id_G03',
  IdI01 = 'Id_I01',
  IdI02 = 'Id_I02',
  IdI03 = 'Id_I03',
  IdI04 = 'Id_I04',
  IdI05 = 'Id_I05',
  IdI06 = 'Id_I06',
  IdI07 = 'Id_I07',
  IdI08 = 'Id_I08',
  IdS01 = 'Id_S01'
}

export type IVehicle = {
  __typename?: 'Vehicle'
  color?: Maybe<Scalars['String']['output']>
  /** Marca */
  make?: Maybe<Scalars['String']['output']>
  /** Modelo */
  model?: Maybe<Scalars['String']['output']>
  type?: Maybe<VehicleType>
  year?: Maybe<Scalars['String']['output']>
}

export type IVehicleInput = {
  color?: InputMaybe<Scalars['String']['input']>
  make?: InputMaybe<Scalars['String']['input']>
  model?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<VehicleType>
  year?: InputMaybe<Scalars['String']['input']>
}

export enum VehicleType {
  Boat = 'BOAT',
  Car = 'CAR',
  Motorcycle = 'MOTORCYCLE'
}

export enum VeriffStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type IVerifyLocationResponse = {
  __typename?: 'VerifyLocationResponse'
  verifiedLocation: ILocation
}
